@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');

.data {
    border-collapse: collapse;
    /* mso-line-height-rule: exactly; */
    text-align: center;
    line-height: 1;
    font-family: "Lato", sans-serif;
}

.ExternalClass * {
    line-height: 100%;
}

span.MsoHyperlink {
    mso-style-priority: 99;
    color: inherit;
}

span.MsoHyperlinkFollowed {
    mso-style-priority: 99;
    color: inherit;
}

.em_white a {
    color: #ffffff !important;
    text-decoration: none !important;
}
a.title:visited {color:#00001b !important;}
a.btn:visited {color:#ffffff !important;}



@media only screen and (max-width:480px) {
    .wrapper {
        width: 100% !important;
    }

    .mob_pad {
        padding: 0 10px !important;
    }

    .full_img {
        width: 100% !important;
        height: auto !important;
    }

    .hide {
        display: none !important;
    }
    .mob_show {
        display: block !important;
        overflow: auto !important;
    }
    .pad0 {
        padding: 0 !important;
    }
    .padl20 {padding-left:20px !important;}
    .p35-20-0 {
        padding: 35px 20px 0 !important;
    }
    .p30-10-0 {
        padding: 30px 10px 0 !important;
    }
    .p25-10 {
        padding: 25px 10px !important;
    }
    .w272 {width: 262px !important;}
}