.select_template{
    margin-bottom: 10px;
}
.wallboard_form{
    border: 2px dotted grey;
    padding: 10px;
    margin-bottom: 10px;
}

.select_year input{
    color:#121926;
    font-weight: 500 !important;
    background: #f8fafc;
    border-radius: 12px;
    width: 100%;
    position: relative;
    padding: 16px 12px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4375em;
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    border-color: #00000033;
    border: 1px solid #00000033;
    box-shadow: none !important;
}

.select_year .react-datepicker__header.react-datepicker-year-header{
    background: #15223f;
    color: white
}

.select_year input:focus, .select_year input:focus-visible{
    border-color: #0088ff !important;
    outline: none;
    border: 2px solid;
}