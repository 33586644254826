.AlignItems{
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 10px;
}

table.accordion {
    width: 100%;
}

.accordion td{
    border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.accordion th{
    text-align: left;
}

/* CreateVersions.css */
.counter-container {
    display: flex;
    align-items: center;
    border: 1px solid #c1c1c1;
    border-radius: 20px;
    padding: 5px;
    width: fit-content;
    background-color: #f8f8f8;
  }
  
  .counter-button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .counter-value {
    font-size: 20px;
    padding: 0 10px;
    /* background-color: #eee; */
    border-radius: 5px;
    display: inline-block;
    min-width: 30px;
    text-align: center;
  }
  
  .Increment{
    display: flex;
    flex-direction: column;
    align-items: center;
  }