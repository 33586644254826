/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.allbuttons {
  display: flex;
  width: 36%;
  justify-content: space-between;
}

.components {
  width: max-content;
  column-gap: 30px !important;
}


.chips::-webkit-scrollbar {
  height: 4px;
}

.chips::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey;
border-radius: 16px;
}
.chips::-webkit-scrollbar-thumb {
background: #555;
border-radius: 10px;
}

/* Handle on hover */
.chips::-webkit-scrollbar-thumb:hover {
background: #555;
}

.delete {
  position: absolute;
  right: 4%;
  top: 8%;
}

.subchild tbody tr td {
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.subchild  tr th {
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.templateLabel label{
background: white;
}

.dataTable {
  border-collapse: inherit !important;
}

p.approve {
  text-align: center;
  color: #00C853;
  font-weight: 600;
  margin: 0;
}

p.pending{
  text-align: center;
  color: #F44336;
  font-weight: 600;
  margin: 0;
}