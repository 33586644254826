/* input#outlined-adornment-AuthorImage {
    opacity: 0;
} */
input#outlined-adornment-BannerImage {
  opacity: 0;
}

.chips::-webkit-scrollbar {
    height: 4px;
}

.chips::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.chips::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

/* Handle on hover */
.chips::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.paging .Mui-selected {
  background-color: #15223f;
}

.paging .Mui-selected:hover {
  background-color: #15223f;
}

.paging .Mui-selected.Mui-disabled {
  color: #fff !important;
  background: #15223f !important;
}

.cropContainer{
  position: 'relative';
  width: '100%';
  height: 200;
  background: '#333';
}

.buttons{
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;
}

/* .KOLImage{
  cursor: pointer;
} */