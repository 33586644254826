div.loading {
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99999999999999999;
}

.loading {
  /* height: 200px; */
  position: relative;
  /* border: 3px solid green; */
}

.chips::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.chips::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 16px;
}
.chips::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

/* Handle on hover */
.chips::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollers::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  margin-top: 6px;
  margin-bottom: 6px;
}
.scrollers::-webkit-scrollbar {
  height: 1px;
  width: 4px;
}

.scrollers::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
  margin-top: 6px;
  margin-bottom: 6px;
}

/* Handle on hover */
.scrollers::-webkit-scrollbar-thumb:hover {
  background: #555;
}