td {
  padding: 0 12px;
  text-align: justify;
  vertical-align: top;
}
td table tr td {
  padding: 0;
}
p {
  font-size: 14px;
}
table {
  margin: 0 auto;
}
/* @font-face {
  font-family: "Korolev";
  src: url("../../../../../../fonts/Korolev\ Condensed\ W04\ Bold.ttf");
} */

@font-face {
  font-family:"korolev-condensed";
src:url("https://use.typekit.net/af/364087/00000000000000007735e8c4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/364087/00000000000000007735e8c4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/364087/00000000000000007735e8c4/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}
@font-face {
  font-family: "Trade-Gothic-LT-Std-Regular";
  src: url("../../../../../../fonts/Trade-Gothic-LT-Std-Regular.otf");
}
@font-face {
  font-family: "AGaramond-Regular";
  /* src: url("../../../../../../fonts/AGaramond-Regular.otf"); */
  src: url("../../../../../../fonts/AGaramondPro-Regular.otf");

}

@font-face {
  font-family: "impact";
  src: url("../../../../../../fonts/impact.ttf");
}
/* p {
    font-family: AGaramond-Regular;
    line-height: 1.2;
    font-size: 15px;
  } */

h3.editor {
  margin: 0;
}

.mce-content-body h3 {
  margin: 0;
  font-size: 21px !important;
  text-align: left;
  font-family: "korolev-condensed";
  line-height: 1.2;
  letter-spacing: -0.5px;
  /* text-transform: capitalize !important; */
}

.mce-content-body:focus-visible {
  outline: none;
}

.mce-content-body p {
  margin: 6px 0 0 0;
  /* word-spacing: -0.4px !important; */
  font-size: 14.5px !important;
  hyphens: auto !important;
  word-spacing: 0px !important;
  text-rendering: optimizeLegibility;
}

.main-section {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  flex-wrap: nowrap;
  width: 100%;
}

.col-3 {
  width: 100%;
  /* height: 1614px; */
  max-width: 291px;
}

p.auth-img {
  float: left;
  width: 25%;
  margin-right: 12px;
  border-bottom: 1px solid #001b49;
  padding-bottom: 6px;
  color: #001b49;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 17px;
}

/* p.auth-img strong {
    font-size: 15px;
    margin-right: 7px;
    font-family: "Korolev";
  } */

/* p {
    font-family: "AGaramond-Regular";
    line-height: 1.2;
    font-size: 15px;
    margin: 0;
  } */

.col_3_content {
  background: #eeeeef;
  padding: 0 12px;
  margin-top: -20px;
  padding-top: 15px;
}

.col_3_content.col-3_section h2 {
  color: #0083a9;
  padding: 0;
  text-align: left;
}

.below-block {
  width: 100%;
  height: 100%;
  background: #e6e7e8;
  position: relative;
}

h2.missed-title {
  margin: 0;
}

.auth-name {
  border: 1px dashed green;
  height: 20px;
  width: 50px;
  margin-top: 7px;
}

/* .auth-details {
  padding: 10px;
  padding-bottom: 0;
} */

.first_col1 .auth-details {
  padding: 12px 12px 12px 12px;
}

.second_col .auth-details {
  padding: 12px 12px 12px 12px;
}

.auth-details.second_para-below {
  padding-top: 0;
  /* margin-top: -12px; */
}

.col-3 {
  width: 100%;
  /* max-width: 540px; */
  display: unset;
  position: relative;
}
/* .col-4 {
    display: none;
  } */

.new-section {
  width: 100%;
  height: 363px;
  position: relative;
}

.col-3 h2.missed-title {
  background: #fff;
  padding: 15px 0 0 25px;
  text-align: left;
  font-size: 45px;
}

.two-whitecols {
  display: flex;
  gap: 0px;
  /* padding: 13px; */
  /* justify-content: space-between; */
  /* height: auto !important; */
}
.first_col1,
.second_col {
  background: #fff;
  position: relative;
  width: 281px;
  /* height: max-content; */
}
.new-section .barcode-box4 {
  top: 10em;
  left: 9em;
}

.first_col1 {
  transform: unset;
}
.auth-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.col_2_1_content p.auth-img span {
  color: #bc8950;
  font-size: 11px;
  font-weight: 600;
  display: block;
}

.auth-details p {
  text-align: justify;
}

.auth-details h2 {
  color: #7d9ba9;
}
/* div#pdfContainer,  .ContentEditor {
    height: 1158px;
  } */
.first_col1,
.second_col {
  /* height: auto !important; */
  height: 1160px !important;
}

.showVersions {
  overflow: visible !important;
}

.two-whitecols{
  border: 1px solid;
}

.second_col {
  /* margin-left: auto; */
}

 .two-whitecols {
  background: #fff;
}

/* div#titles1 p{
  margin:0 !important; 
}

div#editorTitle3 h3 {
  margin-top: 6px;
} */

.photo-credit{
  font-size: 12px;
  font-family:'AGaramond-Regular' !important;
}

#caption_c5 p, #caption_c6 p, #caption_c7 p, #caption_c8 p{
  margin-top: 0;
  font-family:'AGaramond-Regular' !important;
}